import React, { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));
const Reservations = Loadable(lazy(() => import('../views/reservations/Reservations')));
const ReservationsSummary = Loadable(lazy(() => import('../views/reservations/ReservationsSummary')));
const HotelRoomDetails = Loadable(lazy(() => import('../components/reservations/hotelRoomDetails/HotelRoomDetails')));
const Location = Loadable(lazy(() => import('../views/locations/Locations')));
const Agents = Loadable(lazy(() => import('../views/agents/Agents')));
const B2BUsers = Loadable(lazy(() => import('../views/B2BUsers/B2BUsers')));

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '/dashboard', element: <Dashboard /> },
      { path: '/welcome', element: <Dashboard /> },
      { path: '/locations', element: <Location /> },
      { path: '/agents', element: <Agents /> },
      { path: '/b2busers', element: <B2BUsers /> },
      {
        path: '/reservations',
        element: <Outlet />,
        children: [
          { path: 'new-reservations', element: <Reservations /> },
          { path: 'my-reservations', element: <ReservationsSummary /> },
          { path: ':propertyId/hotel-room-details', element: <HotelRoomDetails /> }, 
        ],
      },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/maintenance', element: <Maintenance /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
