import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import  ManageHotelSlice  from './reservations/ManageHotelSlice';
import LocationManagerSlice from './location/LocationManagerSlice';
import AgentManagerSlice from './agent/AgentManagerSlice';
import FilterHotelSlice from './reservations/FilterHotelSlice';
import SearchHotelSlice from './reservations/SearchHotelSlice';
import HotelRoomDetailSlice from './reservations/HotelRoomDetailSlice';
import ReservationsManagerSlice from './reservations/ReservationsManagerSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    hotelReducer: ManageHotelSlice,
    locationReducer: LocationManagerSlice,
    agentReducer: AgentManagerSlice,
    hotelFilterReducer: FilterHotelSlice,
    hotelSearchReducer: SearchHotelSlice,
    hotelRoomDetailReducer: HotelRoomDetailSlice,
    reservationsSummaryReducer: ReservationsManagerSlice
  },
});

export default store;
