export const appConfig ={
    authRedirectUrl : window.envConfig?.authRedirectUrl ?? process.env.REACT_APP_REDIRECT_URI,
    apiBaseUrl : window.envConfig?.apiBaseUrl ?? process.env.REACT_APP_API_BASE_URL,
    clientId : window.envConfig?.clientId ?? process.env.REACT_APP_CLIENT_ID,
    authority : window.envConfig?.authority ?? process.env.REACT_APP_AUTHORITY,
    knownAuthorities : window.envConfig?.knownAuthorities ?? process.env.REACT_APP_KNOWN_AUTHORITIES,
    loginScope: window.envConfig?.loginScope ?? process.env.REACT_APP_LOGIN_SCOPE,
    tenantApi: window.envConfig?.tenantApi ?? process.env.REACT_APP_TENANT_API,
    publicUrl: process.env.PUBLIC_URL
}

export const msalConfig = {
    auth: {
        clientId : appConfig.clientId, 
        authority : appConfig.authority,
        knownAuthorities: [appConfig.knownAuthorities],
        redirectUri: appConfig.authRedirectUrl
    },
    cache: { cacheLocation: 'sessionStorage', storeAuthStateInCookie: false },
    system: {
        iframeHashTimeout: 3000
    },
    telemetry: {
        application: {
            appName: "OKLO Polaris",
            appVersion: "1.0.0",
        },
    },
}

export const loginRequest = {
    scopes: [appConfig.loginScope]
}

export default appConfig;