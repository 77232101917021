import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    reservationSummaryList: []
};

export const ReservationsManagerSlice = createSlice({
    name: 'reservations',
    initialState,
    reducers: {
        getReservationSummaryList: (state, action) => {
            state.reservationSummaryList = action.payload;
        }
    }
})

export const { getReservationSummaryList } = ReservationsManagerSlice.actions;

export const fetchReservationSummaryList = (instance, accounts, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance, accounts);
        const result = await client.getB2BReservationSummary.get(criteria);
        console.log(result);
        
        dispatch(getReservationSummaryList(result));
        return result;
    } catch (error) {
        console.log(error);

    }
}

export default ReservationsManagerSlice.reducer;