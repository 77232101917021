import React, { useEffect, useState } from "react";
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";
import Router from './routes/Router';
import { ThemeSettings } from './theme/Theme';
import ScrollToTop from './components/shared/ScrollToTop';
import { CssBaseline, ThemeProvider } from '@mui/material';
import appConfig, { loginRequest } from './authConfig';
import useSessionStorageNonString from "./hooks/useSessionStorageNonString";
import useLocalStorage from "./hooks/useLocalStorage";
import Error from "./views/authentication/Error";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "./views/spinner/Spinner";
import TenantsApiClient from "./api/TenantsApiClient";

const ProfileContent = () => {
	const { instance, accounts } = useMsal();

	const [signingIn, setSigningIn] = useState(false);
	const [error, setError] = useState(null);
	const [retryCount, setRetryCount] = useState(0);
	const [profile, setProfile] = useSessionStorageNonString(
		"polaris-user-profile",
		{}
	);
	const [tenantId, setTenantId] = useLocalStorage("current-tenant", '00000000-0000-0000-0000-000000000000');

	useEffect(() => {
		async function getUserProfile() {
			if (profile && profile.tenants) {
				return;
			}
			setRetryCount(retryCount + 1);

			if (retryCount <= 3) {
				setSigningIn(true);

				try {
					const token = await instance.acquireTokenSilent({
						account: accounts[0],
						scopes: loginRequest.scopes,
					});
					const client = new TenantsApiClient(
						appConfig.tenantApi,
						token.accessToken
					);					
					const data = await client.userProfile.get();
					setProfile(data);
				} catch (e) {
					setError(e);
				} finally {
					setSigningIn(false);
				}
			}
		}

		getUserProfile();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accounts[0]]);

	useEffect(() => {

		async function setCurrentTenant() {
			if (profile && profile.tenants) {
				let myTenant = profile.tenants.find((i) => i.tenantId === tenantId);
				if (!myTenant) {
					myTenant = profile.tenants[0];
				}
				setTenantId(myTenant.tenantId);
			}
		}
		setCurrentTenant();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile]);

	const routing = useRoutes(Router);
	const theme = ThemeSettings();

	if (error != null) {
		return <Error />;
	}

	if (signingIn) {
		return <LoadingComponent />;
	}

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<ScrollToTop>{routing}</ScrollToTop>
		</ThemeProvider>
	);
};

const ProtectedComponent = ({ children }) => {
	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={loginRequest}
			errorComponent={ErrorComponent}
			loadingComponent={LoadingComponent}
		>
			{children}
		</MsalAuthenticationTemplate>
	);
};

function ErrorComponent({ error }) {
	return <p>An Error Occurred: {JSON.stringify(error)}</p>;
}

function LoadingComponent() {
	return <Spinner />
}

function App() {
	return (
		<BrowserRouter>
			<ProtectedComponent>
				<ProfileContent />
				<ToastContainer />
			</ProtectedComponent>
		</BrowserRouter>
	);
}

export default App;
