import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    searchCriteria: {},
};
export const SearchHotelSlice = createSlice({
    name: 'searchHotel',
    initialState,
    reducers: {
        setHotelSearchCriteria: (state, action) => {
            state.searchCriteria = action.payload;
        },
        clearHotelSearchCriteria: (state) => {
            state.searchCriteria = {};
        },
    },
});

export const { setHotelSearchCriteria, clearHotelSearchCriteria } = SearchHotelSlice.actions;

export default SearchHotelSlice.reducer;