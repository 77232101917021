
import appConfig, { loginRequest } from "../authConfig";
import OkloReservationsApiClient from "./OkloReservationsApiClient";
import { InteractionRequiredAuthError, BrowserAuthError } from '@azure/msal-browser'

const ApiClientConnector = async (instance, accounts) => {
	const tenantId = localStorage.getItem('current-tenant') ?? "";
	let token = null;
	try{
		token = await instance.acquireTokenSilent({
			account: accounts[0],
			scopes: loginRequest.scopes,
			});	
	}
	catch(error){
		if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
			token = await instance.acquireTokenPopup({
				account: accounts[0],
				scopes: loginRequest.scopes,
				});
		}
	}

	if(token){
		return new OkloReservationsApiClient(
			appConfig.apiBaseUrl,
			token.accessToken,
			tenantId
		);
	}

	throw new Error('Failed to authenticated the user automatically.');

}

export default ApiClientConnector;