import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    locationList: [],
};

export const LocationManagerSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        getLocationList: (state, action) => {
            state.locationList = action.payload;
        },
    }
})

export const { getLocationList } = LocationManagerSlice.actions;

export const fetchLocations = (instance, accounts, criteria) => async (dispatch) => {
    const client = await ApiClientConnector(instance, accounts);
    const result = await client.locations.get(criteria);
    dispatch(getLocationList(result));
    return result;
}

export default LocationManagerSlice.reducer;